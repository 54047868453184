<template>
	<b-container fluid class="casinoContainer px-0">
		<base-image :imgSrc="homeCasinoImage" mode="bannerMax" :isFullUrl="false" />
		<div class="casinoContent">
			<div class="homeLogoImg">
				<base-image :imgSrc="homeCasinoLogo" mode="bannerMax" :isFullUrl="false" />
			</div>
			<div class="homeLiveCasinoDesc d-flex align-items-center justify-content-center">
				{{ $t("HomeLiveCasino.LiveCasinoDesc") }}
			</div>
			<b-button variant="main-play" v-if="isLogin && isMaintenance" @click="maintenancepopup">{{ $t("HomeLiveCasino.PlayNow") }}</b-button>
			<b-button variant="main-play" v-else-if="isLogin" @click="startPlay()">{{ $t("HomeLiveCasino.PlayNow") }}</b-button>
			<b-button variant="main-play" v-else v-b-modal="'login-modal'">{{ $t("HomeLiveCasino.PlayNow") }}</b-button>
		</div>
	</b-container>
</template>
<style scoped>
.btn-main-play {
	padding: 0.8rem 3rem;
	border-radius: 29px;
	margin-top: 2.5rem;
	font-size: 1.1rem;
	background: linear-gradient(90deg, #f21679 -6.32%, #5b15dd 106.74%);
}
.casinoContainer {
	position: relative;
	max-width: 2000px !important;
}
.casinoContent {
	position: absolute;
	height: auto;
	width: 40%;
	top: 20%;
	left: 2vw;
}
.homeLogoImg {
	margin: auto;
	width: 27.5vw;
	height: 100%;
}
.homeLiveCasinoDesc {
	font-size: 1.1rem;
}

@media (max-width: 767px) {
	.casinoContent {
		top: 5%;
	}
	.btn-main-play {
		padding: 0.5rem 1rem;
		margin-top: 0.2rem;
		white-space: nowrap;
	}
	.homeLiveCasinoDesc {
		font-size: 0.8rem;
	}
}
@media (min-width: 2000px) {
	.casinoContent {
		left: 1vw;
	}
	.homeLogoImg {
		width: 20.5vw;
	}
}
</style>
<script>
import { GameType } from "@/common/GameType.ts";
import { getDefaultCultureCode } from "@/currentCulture";
export default {
	data() {
		return {
			homeCasinoLogo: "aesexy/assets/images/Home/Sexybaccarat.png",
			homeCasinoImage: "aesexy/assets/images/Home/Sexybaccarat_banner.png",
		};
	},
	components: {},
	methods: {
		async startPlay() {
			const currentLocaleID = getDefaultCultureCode();
			let loader = this.$loading.show();
			await this.$store
				.dispatch("games/fetchLaunchGameUrl", {
					GameType: GameType.LIVE_CASINO,
					IsMobile: this.$isMobile(),
					Lang: currentLocaleID,
				})
				.then((url) => {
					loader.hide();
					window.open(url, "_blank");
				})
				.catch(() => {
					loader.hide();
					let toastPosition = this.$isMobile() ? "bottom-center" : "top-center";
					this.toast.error(this.$t("HomeLiveCasino.LaunchGameError"), { position: toastPosition });
				});
		},
		async maintenancepopup() {
			let toastPosition = this.$isMobile() ? "bottom-center" : "top-center";
			this.toast.error(this.$t("HeaderLogin.GameMaintenance"), {
				position: toastPosition,
				timeout: 0,
				//onClose: () => this.redirectHomePage(),
			});
		}
		
	},
	computed: {
		isLogin() {
			return this.$store.getters["identityServer/getLoginStatus"];
		},
		checkIsMemberBankExist() {
			return this.$store.getters["member/getIsMemberBankExist"];
		},
		isMaintenance() {
			return this.$store.getters["vendors/getVendor"].maintenance;		
		}
	},
};
</script>
