<template>
  <b-container fluid class="my-5">
    <b-container class="homePartContent py-3">
      <h2 class="mt-md-5 mb-5 font-weight-bold">{{ $t("HomeHistoryCompany.OnlineCasino") }}</h2>
      <div class="homeHistoryContainer my-5 mx-md-3 py-2 border-gradient border-gradient-purple">
        <div class="homeHistoryContent">
          <i18n-t tag="div" keypath="HomeHistoryCompany.AboutAESexy1" class="homeHistoryTittle mb-4">
            <template #newline>
              <b-col></b-col>
            </template>
          </i18n-t>

          <i18n-t tag="div" keypath="HomeHistoryCompany.AboutDesc" class="textDesc mb-5">
            <template #newline>
              <b-col class="mb-4"></b-col>
            </template>
          </i18n-t>

          <i18n-t tag="div" keypath="HomeHistoryCompany.AboutAESexy2" class="homeHistoryTittle mb-4">
            <template #newline>
              <b-col></b-col>
            </template>
          </i18n-t>
          <ul>
            <li v-for="descItem in desc" :key="descItem" style="list-style-type: '- '">
              <div class="homeHistorySubTittle">{{ $t("HomeHistoryCompany." + descItem.title) }}</div>
              <i18n-t tag="div" :keypath="'HomeHistoryCompany.' + descItem.desc" class="textDesc mb-3">
                <template #newline></template>
              </i18n-t>
            </li>
          </ul>
        </div>
      </div>
    </b-container>
  </b-container>
</template>

<style scoped>
.homeHistoryContainer {
  background: linear-gradient(0deg, rgba(193, 215, 251, 0.2), rgba(193, 215, 251, 0.2));
  border-radius: 10px;
}
.border-gradient {
  border: 10px solid;
  border-image-slice: 1;
  border-width: 5px;
}
.border-gradient-purple {
  border-image-source: linear-gradient(180deg, #6b16d3 0%, #e11685 100%);
}
.homeHistoryContent {
  color: #ffffff;
  text-align: justify;
  font-size: 1rem;
  padding: 2rem 3rem;
}
.homeHistoryTittle {
  font-size: 1.7rem;
  line-height: 32px;
  text-align: left;
}
.homeHistorySubTittle {
  font-size: 1.1rem;
}
ol,
ul {
  padding-left: 0.8rem !important;
}

@media (max-width: 767px) {
  .homeHistoryContent {
    font-size: 0.9rem;
    padding: 1rem 1.1rem;
  }
  .homeHistoryTittle {
    font-size: 1.4rem;
    line-height: 28px;
    text-align: left;
  }
  .homeHistorySubTittle {
    font-size: 1rem;
  }
}
</style>
<script>
export default {
  data() {
    return {
      desc: [
        {
          title: "AboutSub1",
          desc: "AboutSubDesc1",
        },
        {
          title: "AboutSub2",
          desc: "AboutSubDesc2",
        },
        {
          title: "AboutSub3",
          desc: "AboutSubDesc3",
        },
        {
          title: "AboutSub4",
          desc: "AboutSubDesc4",
        },
      ],
    };
  },
  computed: {
    isLogin() {
      return this.$store.getters["identityServer/getLoginStatus"];
    },
    checkIsMemberBankExist() {
      return this.$store.getters["member/getIsMemberBankExist"];
    },
    checkIsLoadMemberBank() {
      return this.$store.getters["member/getOnLoadMemberBankStatus"];
    },
  },
  components: {},
  methods: {},
};
</script>
