export default {
  LIVECASINO: "在线赌场",
  PlayNow: "开始游戏",
  LaunchGameError: "启动游戏失败，请联系客服",
  Seemore: "观看更多",
  HOTGAMES: "热门老虎机游戏",
  moreGames: "更多游戏",
  Play: "开始",
  SexyBaccaratOnline: "性感在线百家乐",
  SexyOnlineRoulette: "性感在线轮盘",
  SexyDragonTiger: "性感龙虎",
  SexySicBo: "性感骰宝",
};
