export default {
	RemainingTurnover: "投注量余额",
	TargetWinover: "总赢额目标",
	TargetTurnover: "流水额目标",
	Slots: "老虎机",
	LiveCasino: "赌场",
	Fishing: "捕鱼",
	Sports: "体育博彩",
	StartDate: "开始日期*",
	EndDate: "结束日期*",
	Submit: "提交",
	TransactionID: "备注号",
	BonusName: "优惠名称",
	BonusAmount: "优惠金额",
	TargetType: "目标类型",
	TargetAmount: "目标金额",
	Status: "状态",
	CreatedDate: "日期",
};
