export default {
  LIVECASINO: "LIVE CASINO",
  PlayNow: "Main Sekarang",
  LaunchGameError: "Permainan pelancaran gagal, sila hubungi Khidmat Pelanggan kami",
  Seemore: "Lihat Lagi",
  HOTGAMES: "PERMAINAN TOP SLOT",
  moreGames: "Banyak Lagi",
  Play: "BERMAIN",
  SexyBaccaratOnline: "BACCARAT SEKSI ONLINE",
  SexyOnlineRoulette: "ROULETTE SEKSI ONLINE",
  SexyDragonTiger: "DRAGON TIGER SEKSI",
  SexySicBo: "SIC BO SEKSI",
};
