<template>
  <div class="FloatingSwitchSiteContainer">
    <div class="SwitchSiteContainer" v-b-modal="'interchange-modal'">
      <div class="SwitchSiteContent">
        <div class="SwitchSiteHead">
          <font-awesome-icon :icon="['fas', 'shuffle']" class="switchIcon" />
        </div>
        <div class="SwitchSiteBody">
          <div class="SwitchSiteBtn">{{ $t("FloatingSwitchSite.SwitchSite") }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
:root {
  --float-switchsite-right: 3.2vw;
  --float-switchsite-brounce-out: 4vw;
  --float-switchsite-out: 13vw;
  --float-switchsite-height: 13vw;
}
</style>
<style scoped>
.FloatingSwitchSiteContainer {
  position: fixed;
  right: var(--float-switchsite-right);
  top: var(--float-switchsite-height);
  z-index: 1020;
  animation: floatBounce 3s infinite;
}

.FloatingSwitchSiteContainer:hover {
  animation: floatOut 0.2s linear forwards;
}

@keyframes floatOut {
  0% {
    right: var(--float-switchsite-right);
  }
  100% {
    right: var(--float-switchsite-out);
  }
}

@keyframes floatBounce {
  0% {
    right: var(--float-switchsite-right);
  }
  10% {
    right: var(--float-switchsite-brounce-out);
  }
  20% {
    right: var(--float-switchsite-right);
  }
  30% {
    right: var(--float-switchsite-brounce-out);
  }
  40% {
    right: var(--float-switchsite-right);
  }
}
.SwitchSiteContent {
  position: relative;
  transform: rotate(0deg);
}
.SwitchSiteHead {
  background: #f21679;
  box-shadow: 0px 0px 20px 6px #9b0046;
  border-radius: 50%;
  padding: 0.8vw;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  position: absolute;
  top: 0;
}
.switchIcon {
  font-size: 2vw;
}
.SwitchSiteBody {
  position: absolute;
  z-index: 9;
  background-color: #9b0046;
  border: 5px solid #f21679;
  border-radius: 20px;
  width: 12vw;
  height: 3vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2vw;
  left: 2vw;
  top: 0.3vw;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
}

@media (max-width: 767px) {
  .FloatingSwitchSiteContainer {
    display: none;
  }
}
</style>
<script>
export default {};
</script>
