export default {
  LIVECASINO: "LIVE CASINO",
  PlayNow: "Play Now",
  LaunchGameError: "Launch game failed, please contact our Customer Service",
  Seemore: "Seemore",
  HOTGAMES: "TOP SLOT GAMES",
  moreGames: "More Games",
  Play: "PLAY",
  SexyBaccaratOnline: "SEXY BACCARAT ONLINE",
  SexyOnlineRoulette: "SEXY ONLINE ROULETTE",
  SexyDragonTiger: "SEXY DRAGON TIGER",
  SexySicBo: "SEXY SIC BO",
};
