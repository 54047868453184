<template>
  <b-container fluid class="footer-row">
    <b-container class="py-4">
      <div class="footer-grid-container">
        <div class="joinUs-container">
          <b-row class="text-start d-none d-md-block">
            <b-col>{{ $t("TheFooter.JoinUs") }}</b-col>
          </b-row>
          <div class="text-start d-flex">
            <b-col class="h2 mt-2"><font-awesome-icon :icon="['fab', 'facebook-f']"> </font-awesome-icon></b-col>
            <b-col class="h2 mt-2"><font-awesome-icon :icon="['fab', 'twitter']"></font-awesome-icon></b-col>
            <b-col class="h2 mt-2"><font-awesome-icon :icon="['fab', 'instagram']"></font-awesome-icon></b-col>
            <b-col class="h2 mt-2"><font-awesome-icon :icon="['fab', 'youtube']"></font-awesome-icon></b-col>
          </div>
        </div>
        <div class="eighteen-container">
          <b-row class="text-start my-4">
            <b-col cols="3"><div class="eighteenText">18+</div></b-col>
            <b-col cols="9" class="gamblingText"
              >{{ $t("TheFooter.Gamblingcanbeaddictive") }},<br />{{ $t("TheFooter.Playresponsibly") }}</b-col
            >
          </b-row>
        </div>

        <div class="information-container">
          <b-row class="text-start d-none d-md-block">
            <b-col>{{ $t("TheFooter.Information") }}</b-col>
          </b-row>
          <b-row class="text-start my-2 informationText" cols-sm="3" cols-md="3" cols-lg="2">
            <router-link :to="'/aboutus'">
              <b-col class="my-2">{{ $t("TheFooter.About") }}</b-col>
            </router-link>
            <router-link :to="'/terms'">
              <b-col class="my-2">{{ $t("TheFooter.Terms") }}</b-col>
            </router-link>
            <router-link :to="'/privacypolicy'">
              <b-col class="my-2">{{ $t("TheFooter.Privacy") }}</b-col>
            </router-link>
            <router-link :to="'/responsiblegaming'">
              <b-col class="my-2">{{ $t("TheFooter.ResponsibleGaming") }}</b-col>
            </router-link>
          </b-row>
        </div>

        <!-- <div class="talkToUs-container">
          <div class="d-flex justify-content-end w-100 my-4">
            <div>
              <b-button variant="talkToUs">{{ $t("TheFooter.TalktoUs") }}</b-button>
            </div>
          </div>
        </div> -->
        <div class="copyRight-container">
          <b-row align-h="end" class="row-cols-auto">
            <div class="my-2">{{ $t("TheFooter.Copyright") }} © 2023. {{ $t("TheFooter.AllRightsReserved") }}</div>
          </b-row>
        </div>
      </div>
    </b-container>
  </b-container>
</template>

<style scoped>
.footer-row {
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
  background: #9b0046;
}
.informationText > a {
  color: #fff !important;
  white-space: nowrap;
  text-decoration: none !important;
}
.eighteenText {
  border-radius: 50%;
  border: 2px solid white;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gamblingText {
  font-size: 0.7rem;
  font-weight: 400;
  text-align: start;
}
.btn-talkToUs {
  background: linear-gradient(104.86deg, #9f4141 0%, #581b1a 100%);
  border-radius: 100px 100px 0px 100px !important;
  border: none;
}
.footer-grid-container {
  display: grid;
  grid-template-areas:
    "joins . . infor . . ."
    "joins . . infor . . copys"
    "eight . . infor . . .";
  grid-auto-columns: 20% 1fr 20%;
}
.joinUs-container {
  grid-area: joins;
}
.information-container {
  grid-area: infor;
}
.talkToUs-container {
  grid-area: talks;
  display: flex;
}
.copyRight-container {
  grid-area: copys;
}
.eighteen-container {
  grid-area: eight;
}
@media (max-width: 767px) {
  .footer-grid-container {
    grid-template-areas:
      "eight eight eight eight ."
      "joins joins joins joins ."
      "infor infor infor infor ."
      ". copys copys copys .";
  }
  .footer-row {
    padding-bottom: 60px;
  }
}
</style>
