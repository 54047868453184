export default {
  ABOUTUS: "ABOUT US",
  Description1:
    "AE Sexy was launched in Malaysia in October 2019. This game is loved by every casino player, so it won the second largest casino game in Malaysia. Game list: GreatBlue, Safari heat, DolphinReef, Iceland, Thai Boxing, Blackjack, Roulette, Baccarat and other games are all inside.",
  Description2:
    "Everyone in Malaysia loves this game because it can be played anytime, anywhere on modern mobile phones, suitable for people over 18 years old,Use your spare time to win more money with this gambling game! Just like the kind of betting you win and win in SkyCasino in Genting Highlands, Are you still hesitating? Start playing now!",
  Description3:
    "You can play on the computer without downloading the android emulator. The application has a computer version and Android or IOS Iphone, it provides you with the best security and protection, you do not have to worry about your data leakage, more than any platform Safety, Download the game client apk right here.",
  Description4:
    "This game function has daily mission bonuses for you to complete and get more rewards every day. The daily social bingo game is AE Sexy launched new features! Every day, there is a free bingo game for all gamers of the day! The minimum reward is 60 and up to 2500 ringgit or more.",
  Description5:
    "Lucky Wheel is also the latest lottery feature launched by AE Sexy ! Players of the day can get free stars, which can be exchanged for Lucky Wheel or Mystery Box! Can collect your stars and use them immediately,It must be used on the day, otherwise it will be refreshed after 24 hours, Remember it, Otherwise you will regret it.",
  Description6:
    "AE Sexy has live casino and electronic slot machines, both in one game application, download and start playing now!",
};
