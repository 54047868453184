<template>
	<HomeBanner></HomeBanner>
	<Announcement></Announcement>
	<HomeSwitchSite></HomeSwitchSite>
	<HomeFastPayment></HomeFastPayment>
	<event-view></event-view>
	<HomeLiveCasino></HomeLiveCasino>
	<HomeHotSlot></HomeHotSlot>
	<HomeHistoryCompany></HomeHistoryCompany>
</template>
<style>
.homeTitle {
	font-style: normal;
	font-weight: 700;
	font-size: 1.5rem;
	line-height: 36px;
	color: #fff;
}
</style>
<script>
import HomeBanner from "@/components/content/Home/HomeBanner.vue";
import HomeHotSlot from "@/components/content/Home/HomeHotSlot.vue";
import Announcement from "@/components/content/Announcement/AnnouncementView.vue";
import HomeLiveCasino from "@/components/content/Home/HomeLiveCasino.vue";
import HomeFastPayment from "@/components/content/Home/HomeFastPayment.vue";
import HomeHistoryCompany from "@/components/content/Home/HomeHistoryCompany.vue";
import HomeSwitchSite from "@/components/content/Home/HomeSwitchSite.vue";
import EventView from "@/components/content/Event/EventView.vue";

export default {
	props: ["token", "time"],
	data() {
		return {};
	},
	components: {
		HomeBanner,
		HomeSwitchSite,
		Announcement,
		HomeLiveCasino,
		HomeHotSlot,
		HomeFastPayment,
		EventView,
		HomeHistoryCompany,
	},
	methods: {},
};
</script>
