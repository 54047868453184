export default {
  Home: "Laman Utama",
  SLOT: "Slots",
  LIVECASINO: "Live Casino",
  Download: "Muat Turun",
  Promotion: "Promos",
  Rank: "Kedudukan",
  "Contact Us": "Hubungi Kami",
  Login: "Log Masuk",
  Language: "Bahasa",
  Deposit: "Deposit",
  Withdrawal: "Pengeluaran",
  "Change Password": "Tukar Kata Laluan",
  Logout: "Log Keluar",
  LogoutSuccess: "Berjaya Log Keluar",
  Profile: "Profil",
  "Bank Info": "Butiran Bank",
  Statement: "Penyata",
  Referral: "Rujukan",
  Interchange: "Tukar Saya",
  GameIDInfo: "Maklumat Akaun Game",
  VIP: "VIP",
};
