export default {
  OnlineCasino: "WE ARE THE MOST TRUSTED ONLINE CASINO",
  AboutAESexy1: "About AE Sexy {newline} The Ultimate Live Casino Experience",
  AboutDesc:
    "AE SEXY is absolutely devoted to make all players enjoying the live casino experience like visit a physical casino. We featured sexy bikini babe and sweetheart girls from all over the world to be our professional and well-trained live dealer in million-dollar live studio to make next revolution of live casino experience. {newline}  AE SEXY packed with all types of casino games, such as baccarat, roulette, sic bo, dragon tiger etc., moreover, also available a list of local games and features exclusively to make the betting more diverse and inclusive.",
  AboutAESexy2: "AE SEXY ADVANTAGES",
  AboutSub1: "Sexy Bikini Girls",
  AboutSubDesc1:
    "First innovation in live casino with sexy bikini live dealers from all over the world, dancing with music and playful interaction with customer.",
  AboutSub2: "Exclusive Casino Halls",
  AboutSubDesc2:
    "Provide unique experience from enthusiastic of Asia to exotic of Europe or uniform theme with different casino halls, such as Sexy Hall, Asia Hall, Theme Hall or Live Hall.",
  AboutSub3: "Innovative Technology",
  AboutSubDesc3:
    "Provide best live casino experience without worrying the limit and bad quality connection by investing millions of dollars in connectivity infrastructure.",
  AboutSub4: "Fair Games Experience",
  AboutSubDesc4:
    "Provide safe and fair betting experience with best image recognition system that record every move of card distribution by sexy dealers.",
};
