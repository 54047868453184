
import { defineComponent } from "vue";
import HeaderLogin from "@/components/content/Header/HeaderLogin.vue";
import HeaderNavBar from "@/components/content/Header/HeaderNavBar.vue";
import MobileSubHeader from "@/components/content/Header/MobileSubHeader.vue";
import SideBar from "@/components/content/Header/SideBar.vue";
import LoginModal from "@/components/modal/LoginModal.vue";
import SignUpModal from "@/components/modal/SignUpModal.vue";
import ForgotPasswordModal from "@/components/modal/ForgotPasswordModal.vue";
import ChangePasswordModal from "@/components/modal/ChangePasswordModal.vue";
import ProfileModal from "@/components/modal/ProfileModal.vue";
import BankInfoModal from "@/components/modal/BankInfoModal.vue";
import DepositModal from "@/components/modal/DepositModal.vue";
import WithdrawalModal from "@/components/modal/WithdrawalModal.vue";
import InterchangeModal from "@/components/modal/InterchangeModal.vue";

export default defineComponent({
  data() {
    return {
      logoUrl: "aesexy/assets/images/NavBar/playtech.png",
    };
  },
  computed: {
    isLogin() {
      return this.$store.getters["identityServer/getLoginStatus"];
    },
    isMobile(){
			return this.$isMobile()
		}
  },
  components: {
    HeaderLogin,
    HeaderNavBar,
    MobileSubHeader,
    SideBar,
    ForgotPasswordModal,
    SignUpModal,
    LoginModal,
    ChangePasswordModal,
    ProfileModal,
    BankInfoModal,
    DepositModal,
    WithdrawalModal,
    InterchangeModal,
  },
});
