<template>
  <b-container fluid class="pt-4 homeSwitchSiteContainer">
    <b-container>
      <b-button variant="switch-site" squared class="home-switchsite-btn" v-b-modal="'interchange-modal'">
        <font-awesome-icon :icon="['fas', 'shuffle']" class="switchIcon" />
        <span class="ms-2">{{ $t("HomeSwitchSite.SwitchSite") }}</span>
      </b-button>
    </b-container>
  </b-container>
</template>
<style scoped>
.homeSwitchSiteContainer {
  display: none;
}
@media (max-width: 767px) {
  .homeSwitchSiteContainer {
    display: block;
  }
  .home-switchsite-btn {
    width: 95%;
    font-size: 1rem !important;
  }
  .btn-switch-site {
    width: 100%;
    border-radius: 19.5px !important;
    background: linear-gradient(90deg, #f21679 -6.32%, #5b15dd 106.74%);
  }
}
</style>
<script>
export default {};
</script>
