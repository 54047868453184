import { GetterTree } from "vuex";
import { RootState } from "@/store/state";
import { MemberAccountStateInterface } from "./state";

export const getters: GetterTree<MemberAccountStateInterface, RootState> = {
	getVendorBalance(state) {
		return state.balance;
	},
	getMemberWinover(state) {
		return state.memberWinover;
	},
	getMemberTurnover(state) {
		return state.memberTurnover;
	},
	getMemberAccount(state) {
		return state.memberAccount;
	},
	getAllSSOMemberBalance(state){
		return state.MemberVendorBalanceByTenantId;
	}
};
