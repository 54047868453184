export default {
	Deposit: "Deposit",
	depositAmount: "*Jumlah Deposit",
	accountBank: "Bank",
	accountName: "Name Akaun",
	accountNumber: "Nombor Akaun",
	AmountRequired: "*Jumlah pengeluaran diperlukan.",
	Min: "Min: {depositMin}",
	Max: "Max: {depositMax}",
	AutoDeposit: "Auto Deposit",
	ManualDeposit: "Manual Deposit",
	TopUp: "Tambah Nilai",
	Currentbalance: "Baki terkini:",
	Browse: "Browse",
	Cancel: "Batal",
	ChooseFile: "Pilih Fail",
	FileNameRequired: "*Resit Muat Naik Diperlukan",
	DepositChannelRequired: "*Saluran Deposit Diperlukan",
	LastLogin: "Log Masuk Kali Terakhir",
	AmountMin: "*Jumlah Nombor sekurang-kurangnya {minValue} ",
	AmountMax: "*Jumlah Nombor tidak lebih daripada {maxValue} ",
	Copy: "Salinan",
	CopySuccess: "Salin Berjaya",
	CopyError: "Salinan Kosong",
	bankRequired: "*Sila pilih bank",
	DepositError: "Deposit Gagal. {error}",
	DepositSubmitted: "Deposit Berjaya Dihantar",
	Player: "Pemain",
	PleaseSelect: "Sila Pilih",
	OnlineTransfer: "Permindahan dalam talian",
	CashDepositMachine: "Mesin Deposit Tunai",
	ATM: "ATM",
	OverTheCounter: "Di Kaunter",
	Cheque: "Periksa",
	PleaseSelectBonus: "Sila Pilih Promosi",
	WinoverExist: "Winover wujud",
	DepositNotAvailable: "Deposit tidak disediakan",
	ClearBalanceBeforeClaimBonus: "Sila kosongkan baki anda sebelum meneruskan tuntutan bonus",
	MakeSureBalanceBelowThreshold:
		"Sila pastikan baki permainan anda berada di bawah {thresholdAmount} sebelum meneruskan tuntutan bonus",
	AllowOneActiveDeposit:
		"Permintaan deposit anda sebelum ini masih dalam proses. Sila hubungi perkhidmatan pelanggan kami jika anda memerlukan sebarang bantuan.",
	TurnoverExist: "Turnover wujud",
	DepositChannel: "Saluran Deposit:",
	QRPay: "QR Pay",
	Bank: "Banks：",
	PaymentMethod: "Cara Pembayaran:",
};
