<template>
  <b-container fluid class="h-100">
    <b-container class="my-md-1 pt-5 pb-3">
      <b-row>
        <b-col cols="4" lg="4">
          <b-col class="imageContent">
            <base-image :imgSrc="homePaymentImg" mode="bannerMax" :isFullUrl="false" />
          </b-col>
          <div class="my-3">
            <i18n-t tag="div" keypath="HomeFastPayment.AutoPayment" class="fastPaymentText">
              <template #newline>
                <b-col class=""></b-col>
              </template>
            </i18n-t>
          </div>
        </b-col>

        <b-col cols="4" lg="4">
          <b-col class="imageContent">
            <base-image :imgSrc="homeCustomerImg" mode="bannerMax" :isFullUrl="false" />
          </b-col>
          <b-col cols="12" class="fastPaymentText my-3">{{ $t("HomeFastPayment.CustomerService") }}</b-col>
        </b-col>

        <b-col cols="4" lg="4">
          <b-col class="imageContent">
            <base-image :imgSrc="homeSecurityImg" mode="bannerMax" :isFullUrl="false" />
          </b-col>
          <b-col cols="12" class="fastPaymentText my-3">{{ $t("HomeFastPayment.Security") }}</b-col>
        </b-col>
      </b-row>
    </b-container>
  </b-container>
</template>
<script>
export default {
  data() {
    return {
      homePaymentImg: "aesexy/assets/images/Home/Wallet.png",
      homeCustomerImg: "aesexy/assets/images/Home/Customer.png",
      homeSecurityImg: "aesexy/assets/images/Home/Security.png",
    };
  },
};
</script>
<style scoped>
.imageContent {
  width: 6.3vw;
  margin: auto;
}
.fastPaymentText {
  font-size: 1.1rem;
  font-weight: 500;
  letter-spacing: 0em;
}

@media (max-width: 767px) {
  .imageContent {
    width: 15vw;
    margin-top: 1rem;
  }
  .fastPaymentText {
    font-size: 0.8rem;
  }
}
</style>
