export default {
  OnlineCasino: "我们是最值得信赖的在线赌场",
  AboutAESexy1: "性感升级 {newline} 在线真人娱乐新视野",
  AboutDesc:
    "AE 性感百家致力于让线上玩家体验到如同亲临实体赌场般的氛围，不仅精选各国性感比基尼美女和甜美女孩，经过严格培训后担任荷官，技术上也使用最高端的影音设备和影像串流技术，更不计成本打造出豪华摄影棚，就是想给玩家们耳目一新的游玩体验。 {newline}  AE 性感百家的游戏种类相当多元，除了主打百家乐之外，也包含经典游戏像是龙虎、轮盘、骰宝等，同时还独家开发各种在地化游戏和功能，给玩家提供最多元的真人娱乐选择。",
  AboutAESexy2: "AE 性感百家优势",
  AboutSub1: "性感比基尼女郎",
  AboutSubDesc1:
    "业界首创性感比基尼荷官发牌，从世界各地挑选各国超优质美女进行严格培训，以性感比基尼搭配音乐舞动，与玩家热情互动",
  AboutSub2: "超火辣特色厅别",
  AboutSubDesc2:
    "性感厅、亚洲厅、主题厅或直播厅，每个厅别都极具特色，引领着玩家体验东南亚的热情，欧洲异国的情调甚至是制服主题的风情万种",
  AboutSub3: "高端技术与设备",
  AboutSubDesc3:
    "重金打造千万级摄影棚，采用最先进的独家串流技术，不被当地网路品质所局限，提供给玩家最稳定的影像和游玩体验，顺畅不延迟",
  AboutSub4: "力求公平公正",
  AboutSubDesc4: "性感荷官现场发牌一览无遗，千万影像辨识系统确保正确性，杜绝任何有争议性牌局，玩家可以安心投注",
};
