export default {
  OnlineCasino: "KAMI ADALAH KASINO ONLINE YANG PALING DIPERCAYAI",
  AboutAESexy1: "Bacarat seksi {newline} Pengalaman Kasino Langsung Terunggul",
  AboutDesc:
    "AE SEXY benar-benar mengabdikan diri untuk membuat semua pemain menikmati pengalaman kasino secara langsung seperti melawat kasino fizikal. Kami menampilkan awek bikini seksi dan gadis kekasih dari seluruh dunia untuk menjadi peniaga langsung kami yang profesional dan terlatih di studio langsung bernilai jutaan dolar untuk membuat revolusi seterusnya pengalaman kasino langsung. {newline} AE SEXY penuh dengan semua jenis permainan kasino, seperti bakarat, rolet, sic bo, dragon tiger dsb., selain itu, juga menyediakan senarai permainan dan ciri tempatan secara eksklusif untuk menjadikan pertaruhan lebih pelbagai dan inklusif.",
  AboutAESexy2: "KELEBIHAN AE SEKSI",
  AboutSub1: "Gadis Bikini Seksi",
  AboutSubDesc1:
    "Inovasi pertama dalam kasino langsung dengan peniaga langsung bikini seksi dari seluruh dunia, menari dengan muzik dan interaksi yang menyeronokkan dengan pelanggan.",
  AboutSub2: "Dewan Kasino Eksklusif",
  AboutSubDesc2:
    "Menyediakan pengalaman unik dari Asia yang bersemangat hingga eksotik Eropah atau tema seragam dengan dewan kasino yang berbeza, seperti Dewan Seksi, Dewan Asia, Dewan Tema atau Dewan Langsung.",
  AboutSub3: "Teknologi Inovatif",
  AboutSubDesc3:
    "Menyediakan pengalaman kasino langsung terbaik tanpa perlu risau had dan kualiti sambungan yang tidak baik dengan melabur berjuta-juta dolar dalam infrastruktur ketersambungan.",
  AboutSub4: " Pengalaman Permainan Adil",
  AboutSubDesc4:
    "Menyediakan pengalaman pertaruhan yang selamat dan adil dengan sistem pengecaman imej terbaik yang merekodkan setiap pergerakan pengedaran kad oleh peniaga seksi.",
};
