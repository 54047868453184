<template>
	<b-container fluid class="my-4">
		<b-container class="homePartContent">
			<b-row>
				<b-col cols="12">
					<div class="hotGameContainer">
						<div class="hotGameList" v-if="isLogin">
							<div class="hotGameItem" v-for="gameItem in gameList" :key="gameItem">
								<div class="hotGameImg">
									<base-image :imgSrc="hotGameImagePath + gameItem.GameImage" mode="bannerMax" :isFullUrl="false" />
								</div>
								<div class="hotGameText">{{ $t("HomeHotSlot." + gameItem.GameName) }}</div>
							</div>
						</div>
						<div class="hotGameList" v-else>
							<div class="hotGameItem" v-for="gameItem in gameList" :key="gameItem" v-b-modal="'login-modal'">
								<div class="hotGameImg">
									<base-image :imgSrc="hotGameImagePath + gameItem.GameImage" mode="bannerMax" :isFullUrl="false" />
								</div>
								<div class="hotGameText">{{ $t("HomeHotSlot." + gameItem.GameName) }}</div>
							</div>
						</div>
					</div>
				</b-col>
			</b-row>
		</b-container>
	</b-container>
</template>
<style scoped>
.hotGameContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	margin: 1rem 0px;
}
.hotGameList {
	display: flex;
	flex-wrap: wrap;
}
.hotGameItem {
	width: 25%;
	padding: 0.8rem;
	cursor: pointer;
}

.hotGameImg {
	width: 15vw;
	height: 100%;
	margin: auto;
	padding: 1.35rem;
	display: flex;
	align-items: flex-end;
}
.hotGameText {
	font-size: 1.1rem;
}

@media (max-width: 767px) {
	.hotGameItem {
		width: 50%;
		margin-top: 1rem;
	}
	.hotGameImg {
		width: 35vw;
		height: unset;
		padding: 0rem;
	}
	.hotGameText {
		font-size: 0.8rem;
		margin-top: 0.5rem;
	}
}
@media (max-width: 1200px) {
}
@media (max-width: 1640px) {
}
</style>
<script>
import { GameType } from "@/common/GameType.ts";
import { getDefaultCultureCode } from "@/currentCulture";

export default {
	data() {
		return {
			homeSlotImage: "aesexy/assets/images/Home/Sport_banner.png",
			hotGameImagePath: "aesexy/assets/images/Home/HotSlotGames/",
			gameList: [
				{
					GameImage: "BACCARAT.png",
					GameName: "SexyBaccaratOnline",
				},
				{
					GameImage: "ROULETTE.png",
					GameName: "SexyOnlineRoulette",
				},
				{
					GameImage: "TIGER-DRAGON.png",
					GameName: "SexyDragonTiger",
				},
				{
					GameImage: "HI-LO.png",
					GameName: "SexySicBo",
				},
			],
		};
	},
	components: {},
	methods: {
		async startPlay() {
			const currentLocaleID = getDefaultCultureCode();
			let loader = this.$loading.show();
			await this.$store
				.dispatch("games/fetchLaunchGameUrl", {
					GameType: GameType.SLOTS,
					IsMobile: this.$isMobile(),
					Lang: currentLocaleID,
				})
				.then((url) => {
					loader.hide();
					window.open(url, "_blank");
				})
				.catch(() => {
					loader.hide();
					let toastPosition = this.$isMobile() ? "bottom-center" : "top-center";
					this.toast.error(this.$t("HomeHotSlot.LaunchGameError"), { position: toastPosition });
				});
		},
	},
	computed: {
		isLogin() {
			return this.$store.getters["identityServer/getLoginStatus"];
		},
		checkIsMemberBankExist() {
			return this.$store.getters["member/getIsMemberBankExist"];
		},
	},
};
</script>
