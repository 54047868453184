<template>
  <b-container fluid class="mobile-bottom-panel d-block d-md-none">
    <div class="d-flex justify-content-around align-items-center bottom-panel-content">
      <b-col cols="auto">
        <router-link class="bottom-panel-item" to="/">
          <font-awesome-icon :icon="['fas', 'house-chimney']" class="bottom-panel-icon"> </font-awesome-icon>
          <div class="bottom-panel-text">{{ $t("MobileFooter.Home") }}</div>
        </router-link>
      </b-col>
      <b-col cols="auto">
        <div class="bottom-panel-item" @click="maintenancepopup" v-if="isLogin && isMaintenance">
          <font-awesome-icon :icon="['fas', 'wallet']" class="bottom-panel-icon"> </font-awesome-icon>
          <div class="bottom-panel-text">{{ $t("MobileFooter.Deposit") }}</div>
        </div>
        <div class="bottom-panel-item" v-else-if="isLogin && !checkIsLoadMemberBank">
          <b-skeleton type="avatar" class="skeleton-icon"></b-skeleton>
          <b-skeleton class="w-100"></b-skeleton>
        </div>
        <div class="bottom-panel-item" data-bs-toggle="modal" :data-bs-target="this.depositModal" v-else>
          <font-awesome-icon :icon="['fas', 'wallet']" class="bottom-panel-icon"> </font-awesome-icon>
          <div class="bottom-panel-text">{{ $t("MobileFooter.Deposit") }}</div>
        </div>
      </b-col>
      <b-col cols="auto">
        <div class="bottom-panel-item" @click="maintenancepopup" v-if="isLogin && isMaintenance">
          <font-awesome-icon :icon="['fas', 'money-bill-transfer']" class="bottom-panel-icon"> </font-awesome-icon>
          <div class="bottom-panel-text">{{ $t("MobileFooter.Withdrawal") }}</div>
        </div>
        <div class="bottom-panel-item" v-else-if="isLogin && !checkIsLoadMemberBank">
          <b-skeleton type="avatar" class="skeleton-icon"></b-skeleton>
          <b-skeleton class="w-100"></b-skeleton>
        </div>
        <div class="bottom-panel-item" data-bs-toggle="modal" :data-bs-target="this.withdrawalModal" v-else>
          <font-awesome-icon :icon="['fas', 'money-bill-transfer']" class="bottom-panel-icon"> </font-awesome-icon>
          <div class="bottom-panel-text">{{ $t("MobileFooter.Withdrawal") }}</div>
        </div>
      </b-col>
      <b-col cols="auto">
        <router-link class="bottom-panel-item" to="/statement" v-if="isLogin && checkIsMemberBankExist === true">
          <font-awesome-icon :icon="['fas', 'file-invoice']" class="bottom-panel-icon"> </font-awesome-icon>
          <div class="bottom-panel-text">{{ $t("MobileFooter.Statement") }}</div>
        </router-link>
        <div
          class="bottom-panel-item"
          v-else-if="isLogin && !checkIsMemberBankExist === false"
          v-b-modal="'bankInfo-modal'"
        >
          <font-awesome-icon :icon="['fas', 'file-invoice']" class="bottom-panel-icon"> </font-awesome-icon>
          <div class="bottom-panel-text">{{ $t("MobileFooter.Statement") }}</div>
        </div>
        <div class="bottom-panel-item" v-else v-b-modal="'login-modal'">
          <font-awesome-icon :icon="['fas', 'file-invoice']" class="bottom-panel-icon"> </font-awesome-icon>
          <div class="bottom-panel-text">{{ $t("MobileFooter.Statement") }}</div>
        </div>
      </b-col>
      <b-col cols="auto">
        <div class="bottom-panel-item" v-b-modal="'interchange-modal'">
          <font-awesome-icon :icon="['fas', 'shuffle']" class="bottom-panel-icon"> </font-awesome-icon>
          <div class="bottom-panel-text">{{ $t("MobileFooter.Switch") }}</div>
        </div>
      </b-col>
    </div>
  </b-container>
</template>
<style scoped>
.mobile-bottom-panel {
  display: block;
  right: 0;
  bottom: 0;
  left: 0;
  height: 60px;
  position: fixed;
  z-index: 999;
  padding: 10px 0px;
  background: #ea2692;
}
.bottom-panel-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  min-width: 20%;
}
.bottom-panel-icon {
  font-size: 1.4em;
}
.bottom-panel-text {
  font-size: 0.7rem;
  margin-top: 0.25rem;
  font-weight: normal;
}
.skeleton-icon {
  width: 1.5em;
  height: 1.5em;
}
</style>
<script>
export default {
  data() {
    return {
      depositModal: "#deposit-modal",
      withdrawalModal: "#withdrawal-modal",
      bankModal: "#bankInfo-modal",
      loginModal: "#login-modal",
      defaultDepositModal: "#deposit-modal",
      defaultWithdrawalModal: "#withdrawal-modal",
    };
  },
  computed: {
    checkIsMemberBankExist() {
      return this.$store.getters["member/getIsMemberBankExist"];
    },
    isLogin() {
      return this.$store.getters["identityServer/getLoginStatus"];
    },
    checkIsLoadMemberBank() {
      return this.$store.getters["member/getOnLoadMemberBankStatus"];
    },
    isMaintenance() {
			return this.$store.getters["vendors/getVendor"].maintenance;		
		}
  },
  methods: {
    setModalPopUp() {
      if (this.isLogin && this.checkIsMemberBankExist === true) {
        this.depositModal = this.defaultDepositModal;
        this.withdrawalModal = this.defaultWithdrawalModal;
      } else if (this.isLogin && this.checkIsMemberBankExist === false) {
        this.depositModal = this.bankModal;
        this.withdrawalModal = this.bankModal;
      } else {
        this.depositModal = this.loginModal;
        this.withdrawalModal = this.loginModal;
      }
    },
    async maintenancepopup() {
			let toastPosition = this.$isMobile() ? "bottom-center" : "top-center";
			this.toast.error(this.$t("HeaderLogin.GameMaintenance"), {
				position: toastPosition,
				timeout: 0,
				//onClose: () => this.redirectHomePage(),
			});
		}
  },
  watch: {
    isLogin() {
      this.setModalPopUp();
    },
    checkIsMemberBankExist() {
      this.setModalPopUp();
    },
  },
  created() {
    this.setModalPopUp();
  },
};
</script>
